import { Box } from "@chakra-ui/react"
import React, { useEffect } from "react"

function BlobBackground() {
  useEffect(() => {
    // If the JS files are not available as modules, load them from the static folder
    const loadScripts = async () => {
      const isSSR = typeof window === "undefined"
      if (isSSR) return

      const blobScript = await import("../../../../static/jsband.js")
      const jsband = (window as any).jsband

      jsband.use("jsband.*")

      var use = jsband.use,
        trace = jsband.trace,
        extend = jsband.extend,
        createClass = jsband.createClass,
        getColorComponents = jsband.getColorComponents,
        rgbToHsl = jsband.rgbToHsl,
        getHslComponents = jsband.getHslComponents,
        getRgbComponents = jsband.getRgbComponents,
        EnterFrameSignal = jsband.EnterFrameSignal,
        Metronome = jsband.Metronome,
        Ease = jsband.Ease,
        Pause = jsband.Pause,
        Wait = jsband.Wait,
        MonoTween = jsband.MonoTween,
        MonoTweenTimebased = jsband.MonoTweenTimebased,
        PolyTween = jsband.PolyTween,
        PolyTweenTimebased = jsband.PolyTweenTimebased,
        Tween = jsband.Tween,
        ColorTween = jsband.ColorTween,
        ColorTweenTimebased = jsband.ColorTweenTimebased,
        Boom = jsband.Boom,
        Boomerang = jsband.Boomerang,
        BoomerangTimebased = jsband.BoomerangTimebased,
        Shift = jsband.Shift,
        ShiftTimebased = jsband.ShiftTimebased,
        CanvasContainer = jsband.CanvasContainer,
        CanvasComposition = jsband.CanvasComposition,
        ImageLayer = jsband.ImageLayer,
        TextLoader = jsband.TextLoader,
        XMLLoader = jsband.XMLLoader,
        AtlasData = jsband.AtlasData,
        AtlasFilmData = jsband.AtlasFilmData,
        AtlasFilmDataSheet = jsband.AtlasFilmDataSheet,
        AtlasImageLayer = jsband.AtlasImageLayer,
        AtlasFilmLayer = jsband.AtlasFilmLayer,
        EventDispatcherBehaivor = jsband.EventDispatcherBehaivor,
        StyleObject = jsband.StyleObject,
        TextLayer = jsband.TextLayer,
        CssProp = jsband.CssProp,
        CssPropNum = jsband.CssPropNum,
        CssPropCompound = jsband.CssPropCompound,
        Queue = jsband.Queue,
        Matrix = jsband.Matrix,
        Point = jsband.Point,
        Shape = jsband.Shape,
        ShapeLayer = jsband.ShapeLayer,
        RectangleLayer = jsband.RectangleLayer,
        CircleLayer = jsband.CircleLayer,
        ShapeRegion = jsband.ShapeRegion,
        CommonMap = jsband.CommonMap,
        SoftMap = jsband.SoftMap,
        HardMap = jsband.HardMap,
        SoftSet = jsband.SoftSet,
        HardSet = jsband.HardSet,
        IdChain = jsband.IdChain,
        HashIterator = jsband.HashIterator,
        FontLoader = jsband.FontLoader,
        DragBehavior = jsband.DragBehavior,
        DragBehaviorEvent = jsband.DragBehaviorEvent,
        CanvasCompositionWrapper = jsband.CanvasCompositionWrapper,
        Swing = jsband.Swing,
        Wave = jsband.Wave,
        Motion = jsband.Motion,
        Motion2D = jsband.Motion2D,
        Path = jsband.Path,
        Tween2D = jsband.Tween2D,
        events = jsband.events,
        shapes = jsband.shapes
      await import("../../../../static/blob.js")
      const BlobAnimation = (window as any).BlobAnimation
      var blob = new BlobAnimation("blob_container", {
        //Blob main settings
        BLOB_SIZE: 250,
        BLOB_DISTANCE: 1000,
        DETALIZATION: 60,
        PERSPECTIVE_DISTORTION: 1,
        ROTATION_SPEED: 5,
        OFFSET_X: 0, //offset in pixels
        OFFSET_Y: 0, //offset in pixels
        PERCENTAGE_OFFSET_X: 0, //offset in percentages
        PERCENTAGE_OFFSET_Y: 0, //offset in percentages

        //Dot settins
        DOT_SIZE: 2,
        DOT_COLOR: "#707b7e",

        //Responsivity settings
        AUTO_SCALE: false,
        VIEWPORT_WIDTH: 1000,
        VIEWPORT_HEIGHT: 1000,

        //Mouse settings
        MOUSE_DISTANCE_MIN: 20,
        MOUSE_DISTANCE_MAX: 400,
        MOUSE_SENSITIVITY: 0.7,
        INERTIAL_TIME: 2,

        //Shape settins
        //Shape main settings
        INITIAL_SHAPE: {
          wave1: { amplitude: 40.659, frequency: 2.802, phase: 0 },
          wave2: { amplitude: 63.736, frequency: 0.824, phase: 3.936 },
          wave3: { amplitude: 0, frequency: 1.923, phase: 0 },
        },
        USE_MORPHING: true,
        USE_WAVE_MOTION: false,
        USE_WAVE_SWING: false,

        //Morphing settings
        MORPHING_AUTOPLAY: true,
        MORPHING_DURATION: 2,
        MORPHING_DELAY: 1,
        MORPHING_TRANSITION_TYPE: "cubic",
        MORPHING_SHAPES: [
          {
            wave1: { amplitude: 3.297, frequency: 2.802, phase: 0 },
            wave2: { amplitude: 71.429, frequency: 0.824, phase: 3.694 },
            wave3: { amplitude: 143.956, frequency: 1.923, phase: 1.381 },
          },
          {
            wave1: { amplitude: 92.308, frequency: 1.484, phase: 4.005 },
            wave2: { amplitude: 87.912, frequency: 2.198, phase: 2.002 },
            wave3: { amplitude: 91.209, frequency: 0.549, phase: 0 },
          },
          {
            wave1: { amplitude: 45.055, frequency: 5.055, phase: 2.624 },
            wave2: { amplitude: 117.582, frequency: 1.154, phase: 5.213 },
            wave3: { amplitude: 19.78, frequency: 2.582, phase: 0 },
          },
          {
            wave1: { amplitude: 45.055, frequency: 3.516, phase: 0.483 },
            wave2: { amplitude: 173.626, frequency: 1.154, phase: 1.346 },
            wave3: { amplitude: 19.78, frequency: 2.582, phase: 1.45 },
          },
          {
            wave1: { amplitude: 32.967, frequency: 1.648, phase: 0.483 },
            wave2: { amplitude: 41.758, frequency: 5.824, phase: 3.521 },
            wave3: { amplitude: 78.022, frequency: 2.582, phase: 5.351 },
          },
          {
            wave1: { amplitude: 36.264, frequency: 5.11, phase: 0.483 },
            wave2: { amplitude: 49.451, frequency: 4.176, phase: 0.518 },
            wave3: { amplitude: 10.989, frequency: 0, phase: 3.798 },
          },
          {
            wave1: { amplitude: 94.505, frequency: 2.692, phase: 4.074 },
            wave2: { amplitude: 96.703, frequency: 0, phase: 6.076 },
            wave3: { amplitude: 97.802, frequency: 1.044, phase: 6.283 },
          },
        ],
      })
    }
    if (typeof window !== "undefined") {
      loadScripts()
    }
  }, [])

  return (
    <Box
      bg="gray.full"
      w="full"
      h="full"
      position="absolute"
      top="0"
      left="0"
      zIndex="-1"
    >
      <Box
        h={["full", "full", "100vh"]}
        w={["full", "full", "80vh"]}
        float="right"
        id="blob_container"
        opacity={["0.1", "0.2", "0.3"]}
      />
    </Box>
  )
}

export default BlobBackground

import React from "react"
import { useDisclosure } from "@chakra-ui/react"
import PageContent from "../gatsby-theme-wild-child/components/chat/PageContent"
import Feedback from "../gatsby-theme-wild-child/components/chat/Feedback"

function Home() {
  const { onOpen, isOpen, onClose } = useDisclosure()

  return (
    <>
      <PageContent onOpen={onOpen} />
      <Feedback isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default Home

export const Head = () => (
  <>
    <title>
      TaxGPT by Virtuzone | World's First AI-Powered UAE Corporate Tax Assistant
    </title>
    <meta
      name="facebook-domain-verification"
      content="0qis6k25boblo98elnosvjz5ws7gtm"
    />
    <meta name="google-site-verification" content="fdkrhMVPDQOPHf3A4czsCkEuBbJ_lkvh8yj01E6fn4Q" />
  </>
)

import React, { useContext, useEffect } from "react"
import { IoMdSend } from "react-icons/io"
import { useChat } from "./ChatProvider"
import { AiOutlineSearch } from "react-icons/ai"
import { SiOpenai } from "react-icons/si"
import ReferenceChecks from "./ReferenceChecks"
import { useRealmApp } from "../../context/RealmApp"
import { AppContext } from "src/gatsby-theme-wild-child/context/ContextProvider"
import {
  Center,
  Text,
  InputGroup,
  Input,
  InputRightAddon,
  IconButton,
  InputLeftAddon,
  Icon,
  HStack,
  VStack,
  Tooltip,
  useBreakpointValue,
  SimpleGrid,
  Button,
  Box,
  Spacer,
  Flex,
} from "@chakra-ui/react"
import Footer from "../common/Footer"

function UserInput(props) {
  const { showTopPrompts } = props
  const { namespace } = useChat()

  const {
    handleSubmit,
    isListening,
    query,
    setQuery,
    setFocused,
    focused,
    gpt4,
    setGpt4,
  } = useChat()

  const buttonSize = useBreakpointValue(["md", "md", "lg"])
  const isMobile = useBreakpointValue({ base: true, md: false })

  const { loading } = useContext(AppContext)
  const { currentUser } = useRealmApp() as any

  useEffect(() => {
    const handleKeyDown = e => {
      if (!focused || isListening) return
      if (e.key === "Enter") {
        handleSubmit()
      }
    }

    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [focused, query])

  return (
    <>
      {showTopPrompts && (
        <VStack
          maxW="1200px"
          w="full"
          mt={{
            base: "90px",
            sm: "10px",
            md: "100px",
            lg: "60px",
            xl: "130px",
            "2xl": "200px",
          }}
          mb={{
            base: "125px",
            sm: "95px",
            md: "45px",
            lg: "45px",
            xl: "50px",
            "2xl": "75px",
          }}
        >
          <Text
            color="dark.700"
            fontSize={["16px", "16px", "18px", "18px", "18px", "18px", "21px"]}
          >
            {namespace?.action}
          </Text>
          <SimpleGrid
            gap={4}
            columns={[1, 1, 2, 2, 4]}
            pb={[1, 0, 0, 0]}
            w="full"
            maxW="1200px"
          >
            {namespace?.types.map((action, index) => (
              <Button
                key={index}
                bg="red.200"
                color="red.full"
                size={buttonSize}
                onClick={() => {
                  if (action.target === null) {
                    handleSubmit(action.key)
                  } else {
                    window.open(action.target.link, "_blank", "noreferrer");
                  }
                }}
                className="prompt-button"
              >
                {action.label}
              </Button>
            ))}
          </SimpleGrid>
        </VStack>
      )}
      <SimpleGrid columns={1} zIndex="1" position="fixed" bottom="0" left="0">
        <Box>
          <Center
            w="100vw"
            pb={isMobile ? 0 : 6}
            p={isMobile ? "10px" : "0px"}
            mb={!showTopPrompts && !isMobile ? "50px" : "0px"}
            bg={isMobile ? "white" : "gray.full"}
          >
            <HStack maxW="1200px" w="full">
              <InputGroup
                rounded="md"
                border={isMobile ? "none" : "1px solid"}
                borderColor="gray.100"
                boxShadow={
                  isMobile ? "none" : "rgba(207, 51, 58, 0.1) 0px 10px 50px"
                }
              >
                <InputLeftAddon
                  height="full"
                  padding="0"
                  bg="white"
                  h={["50px", "50px", "60px"]}
                  children={
                    <IconButton
                      aria-label="search icon"
                      pointerEvents="none"
                      icon={
                        <Icon
                          color="dark.200"
                          as={AiOutlineSearch}
                          boxSize="25px"
                        />
                      }
                    />
                  }
                />
                <Input
                  placeholder={namespace?.promptTitle}
                  _placeholder={{
                    color: "dark.200",
                    fontSize: ["sm", "sm", "md"],
                  }}
                  size="lg"
                  h={["50px", "50px", "60px"]}
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  isDisabled={isListening || loading}
                  _disabled={{ bg: "white" }}
                />
                <InputRightAddon
                  height="full"
                  padding="0"
                  bg="white"
                  h={["50px", "50px", "60px"]}
                  children={
                    <IconButton
                      aria-label="chat"
                      onClick={() => handleSubmit()}
                      isDisabled={isListening}
                      isLoading={isListening}
                      icon={
                        <Icon color="red.full" as={IoMdSend} boxSize="25px" />
                      }
                    />
                  }
                />
              </InputGroup>
              {currentUser?._profile?.data?.email && (
                <>
                  <Tooltip label="Toggle GPT4" placement="right">
                    <IconButton
                      bg={gpt4 ? "red.full" : "gray.100"}
                      aria-label="toggle GPT4"
                      onClick={() => setGpt4(prev => !prev)}
                      icon={
                        <Icon
                          color={gpt4 ? "white" : "dark.full"}
                          as={SiOpenai}
                          boxSize="25px"
                        />
                      }
                    />
                  </Tooltip>
                  <ReferenceChecks />
                </>
              )}
            </HStack>
          </Center>
        </Box>
        {showTopPrompts && (
          <Box bg="gray.full">
            <Center
              w="100vw"
              pb={isMobile ? 6 : 0}
              pt={isMobile ? "0px" : "30px"}
              p={isMobile ? "10px" : ""}
            >
              <HStack
                maxW="1200px"
                w="full"
                marginTop={["", "", ""]}
                flexDirection="column"
                borderTop={isMobile ? "none" : "1px solid"}
                borderTopColor={isMobile ? "none" : "gray.200"}
              >
                <Footer />
              </HStack>
            </Center>
          </Box>
        )}
      </SimpleGrid>
    </>
  )
}

export default UserInput

import React from "react"
import { useChat } from "./ChatProvider"
import HeroSection from "./HeroSection"
import Transcript from "./Transcript"

function PageContent({ onOpen }) {
  const { transcriptHistory } = useChat()
  const isChatting = transcriptHistory?.length > 0 || false

  return isChatting ? <Transcript onOpen={onOpen} /> : <HeroSection />
}

export default PageContent

import React from "react"
import { useChat } from "./ChatProvider"
import {
  HStack,
  Circle,
  Text,
  Box,
  useBreakpointValue,
  Icon,
  IconButton,
  ButtonGroup,
  Flex,
} from "@chakra-ui/react"
import Button from "gatsby-theme-wild-child/src/components/Button"

import { FiThumbsUp, FiThumbsDown } from "react-icons/fi"
import { useRealmApp } from "../../context/RealmApp"
import { useState } from "react"
import ContactForm from "./ContactForm"

import { BiErrorCircle } from "react-icons/bi"

function AssistantResponse({ transcript, onOpen }) {
  const {
    setPositiveFeedback,
    feedbackContent,
    setFeedbackContent,
    setShowContactForm,
    namespace,
  } = useChat() as any

  const { currentUser } = useRealmApp() as any
  const [showContactUsForm, setShowContactUsForm] = useState(false)

  const boxWidth = useBreakpointValue({ base: "100%", md: "60%" })

  const isMobile = useBreakpointValue({ base: true, md: false })

  const toggleForm = () => {
    setShowContactUsForm(true)
    setShowContactForm(true)
  }

  const UserInput = () => {
    return (
      <HStack
        w="full"
        h="full"
        position="relative"
        spacing={[4, 4, 8]}
        justifyContent="flex-start"
        minH="53px"
      >
        {/* <Circle
          bg="white"
          aria-label="ChatVZ"
          size={["40px", "40px", "50px"]}
          fontWeight="bold"
          color="dark.default"
          boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 50px"
        >
          U
        </Circle> */}
        <Box
          py={3}
          px={[4, 4, 12]}
          bg="red.100"
          roundedRight="20px"
          roundedBottomLeft="20px"
        >
          <Text flex="1" fontSize={["md", "md", "lg"]}>
            {transcript.content}
          </Text>
        </Box>
      </HStack>
    )
  }

  const ChatVzInput = () => {
    return (
      <>
        <HStack
          w="full"
          h="full"
          position="relative"
          spacing={[4, 4, 8]}
          justifyContent="flex-end"
          alignItems="flex-end"
          minH="53px"
        >
          <Box
            py={4}
            px={[4, 4, 12]}
            bg="white"
            roundedLeft="20px"
            roundedBottomRight="20px"
            w="full"
          >
            <Text flex="1" fontSize={["md", "md", "lg"]}>
              {transcript.content}
            </Text>

            <Flex justifyContent={"flex-start"}>
              {currentUser?._profile?.data?.email && (
                <HStack pt="5" spacing={1}>
                  <IconButton
                    aria-label="Feedback positive"
                    icon={
                      <Icon as={FiThumbsUp} color="green.500" boxSize="20px" />
                    }
                    onClick={() => {
                      setPositiveFeedback(true)
                      setFeedbackContent({
                        ...feedbackContent,
                        response: transcript.content,
                      })
                      onOpen()
                    }}
                    size="md"
                  />
                  <IconButton
                    aria-label="Feedback positive"
                    icon={
                      <Icon as={FiThumbsDown} color="red.500" boxSize="20px" />
                    }
                    onClick={() => {
                      setPositiveFeedback(false)
                      setFeedbackContent({
                        ...feedbackContent,
                        response: transcript.content,
                      })
                      onOpen()
                    }}
                    size="md"
                  />
                </HStack>
              )}
            </Flex>

            <Flex justifyContent={"flex-end"}>
              <ButtonGroup
                pt="5"
                spacing={isMobile ? 0 : 4}
                justifyContent={isMobile ? "center" : "flex-end"}
                flexDirection={isMobile ? "column" : "row"}
              >
                {transcript?.trigger?.showCostCalculatorBtn.show && (
                  <Button
                    bg="red.full"
                    zIndex="0"
                    textAlign="center"
                    mb={2}
                    to="https://calculator.vz.ae/"
                  >
                    Calculate Cost
                  </Button>
                )}

                {transcript?.trigger?.showContactBtn.show && (
                  <Button
                    //onClick={toggleForm}
                    bg="red.full"
                    zIndex="0"
                    mb={2}
                    textAlign="center"
                    to="https://calendly.com/virtuzone-cs/corporate-tax-consultation?utm_source=TaxGPT&utm_medium=Website&utm_campaign=CorporateTaxCalendly"
                    className="cta-link"
                  >
                     Talk to a consultant
                  </Button>
                )}

                {transcript?.trigger?.showContactBtn.show && (
                  <Button
                    bg="red.full"
                    zIndex="0"
                    mb={2}
                    textAlign="center"
                    to="https://go.vz.ae/corporate-tax-whitepaper/"
                    className="cta-link"
                  >
                    Claim free tax guide
                  </Button>
                )}
              </ButtonGroup>
            </Flex>
          </Box>

          {namespace?.chatIcon?.show && (
            <Circle
              bg="red.full"
              aria-label={namespace?.chatIcon?.title}
              size={["40px", "40px", "50px"]}
              fontWeight="bold"
              color="white"
              boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 20px"
            >
              {namespace?.chatIcon?.label}
            </Circle>
          )}
        </HStack>

        {showContactUsForm && (
          <HStack
            w="full"
            h="full"
            position="relative"
            spacing={[4, 4, 8]}
            justifyContent="flex-end"
            alignItems="flex-end"
            minH="53px"
          >
            <Box
              py={5}
              px={6}
              bg="white"
              roundedLeft="20px"
              roundedBottomRight="20px"
              w={boxWidth}
            >
              <ContactForm transcript={transcript} />
            </Box>

            {namespace?.chatIcon?.show && (
              <Circle
                bg="red.full"
                aria-label={namespace?.chatIcon?.title}
                size={["40px", "40px", "50px"]}
                fontWeight="bold"
                color="white"
                boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 20px"
              >
                {namespace?.chatIcon?.label}
              </Circle>
            )}
          </HStack>
        )}
      </>
    )
  }

  const ErrorInput = () => {
    return (
      <>
        <HStack
          w="full"
          h="full"
          position="relative"
          spacing={[4, 4, 8]}
          justifyContent="flex-end"
          alignItems="flex-end"
          minH="53px"
        >
          <Box
            py={4}
            px={[4, 4, 12]}
            bg="#FBECEF"
            roundedLeft="20px"
            roundedBottomRight="20px"
            border={"1px solid #F74040"}
            w="full"
          >
            <Flex align="center">
              <Icon
                as={BiErrorCircle}
                boxSize={6}
                mr={"33px"}
                color="#F64040"
                w={"33px"}
                h={"33px"}
              />
              <Text
                flex="1"
                fontSize={"18px"}
                color={"#F64040"}
                fontStyle={"normal"}
                lineHeight={"34px"}
                fontWeight={"500"}
              >
                {transcript.content}
              </Text>
            </Flex>
            <Flex justifyContent={"flex-end"}>
              <ButtonGroup
                spacing={isMobile ? 0 : 4}
                justifyContent={isMobile ? "center" : "flex-end"}
                flexDirection={isMobile ? "column" : "row"}
              >
                <Button
                  onClick={toggleForm}
                  bg="red.full"
                  zIndex="0"
                  mb={2}
                  textAlign="center"
                >
                  Contact Us
                </Button>
              </ButtonGroup>
            </Flex>
          </Box>

          {namespace?.chatIcon?.show && (
            <Circle
              bg="red.full"
              aria-label={namespace?.chatIcon?.title}
              size={["40px", "40px", "50px"]}
              fontWeight="bold"
              color="white"
              boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 20px"
            >
              {namespace?.chatIcon?.label}
            </Circle>
          )}
        </HStack>

        {showContactUsForm && (
          <HStack
            w="full"
            h="full"
            position="relative"
            spacing={[4, 4, 8]}
            justifyContent="flex-end"
            alignItems="flex-end"
            minH="53px"
          >
            <Box
              py={5}
              px={6}
              bg="white"
              roundedLeft="20px"
              roundedBottomRight="20px"
              w={boxWidth}
            >
              <ContactForm transcript={transcript} />
            </Box>

            {namespace?.chatIcon?.show && (
              <Circle
                bg="red.full"
                aria-label={namespace?.chatIcon?.title}
                size={["40px", "40px", "50px"]}
                fontWeight="bold"
                color="white"
                boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 20px"
              >
                {namespace?.chatIcon?.label}
              </Circle>
            )}
          </HStack>
        )}
      </>
    )
  }

  return (
    <>
      {transcript.role === "user" && <UserInput />}
      {transcript.role === "error" && <ErrorInput />}
      {transcript.role === "assistant" && <ChatVzInput />}
    </>
  )
}

export default AssistantResponse

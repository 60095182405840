import React from "react"
import { useChat } from "./ChatProvider"
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react"

function Feedback({ isOpen, onClose }) {
  const {
    positiveFeedback,
    feedbackContent,
    setFeedbackContent,
    setSaving,
    handleFeedback,
    saving,
  } = useChat() as any

  function handleCheckbox(e) {
    const { name } = e.target
    const reasons = [...feedbackContent.reasons]
    if (reasons.includes(name)) {
      const index = reasons.indexOf(name)
      reasons.splice(index, 1)
    } else {
      reasons.push(name)
    }
    setFeedbackContent(prev => ({
      ...prev,
      reasons,
    }))
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent maxW="900px">
        <ModalHeader>Provide Feedback</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {positiveFeedback && (
            <Box>
              <Textarea
                minH="120px"
                placeholder="What do you like about this response?"
                onChange={e =>
                  setFeedbackContent({
                    ...feedbackContent,
                    message: e.target.value,
                  })
                }
              />
            </Box>
          )}
          {!positiveFeedback && (
            <Box>
              <Textarea
                minH="120px"
                placeholder="Please specify what could be better about this response"
                minW="500px"
                onChange={e =>
                  setFeedbackContent({
                    ...feedbackContent,
                    message: e.target.value,
                  })
                }
              />
              <HStack pt={4} spacing={4}>
                <Checkbox size="md" name="toolong" onChange={handleCheckbox}>
                  Too Long
                </Checkbox>
                <Checkbox size="md" name="untrue" onChange={handleCheckbox}>
                  This isn't true
                </Checkbox>
                <Checkbox size="md" name="brokerule" onChange={handleCheckbox}>
                  Broke a rule
                </Checkbox>
                <Checkbox size="md" name="other" onChange={handleCheckbox}>
                  Other (please specify)
                </Checkbox>
              </HStack>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            bg="gray.100"
            color="dark.full"
            mr={3}
            onClick={() => {
              setFeedbackContent({
                response: "",
                reasons: [],
                message: "",
              })
              setSaving(false)
              onClose()
            }}
          >
            Cancel
          </Button>
          <Button
            bg="red.full"
            mr={3}
            onClick={async () => {
              await handleFeedback()
              onClose()
            }}
            isDisabled={saving}
            isLoading={saving}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default Feedback

import React from "react"
import { useState } from "react"
import axios from "axios"
import {
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  Textarea,
  Select,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
} from "@chakra-ui/react"

import { parsePhoneNumber } from "libphonenumber-js"

import PhoneNumberInput from "../common/PhoneNumberInput"
import { COUNTRIES } from "../../../../wc-config/countries"
import { useChat } from "./ChatProvider"

const types = [
  {
    value: 'Free Zones',
    label: 'Free Zones'
  },
  {
    value: 'Mainland',
    label: 'Mainland'
  },
  {
    value: 'Freelance',
    label: 'Freelance'
  },
  {
    value: 'Off-shore',
    label: 'Offshore'
  },
  {
    value: 'Other',
    label: 'Other'
  },
  {
    value: 'None',
    label: 'None'
  }
];

function ContactForm() {
  const searchParams = new URLSearchParams(document.location.search)
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }))

  /**
   * STATES
   */
  const { sessionId } = useChat()
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [unTouched, setUnTouched] = useState(true)
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    nationality: "",
    message: "",
    type_business: ""
  })
  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: "",
    number: "",
    parsedNumber: "",
    iso2: "AE",
    selectedCountry: "ARE",
  })
  const [errors, setErrors] = useState({})

  const handleSubmit = async e => {

    e.preventDefault()
    for (const key in formData) {
      if (!formData[key].length) {
        handleBlur({
          target: {
            name: key,
            value: formData[key],
          },
        })

        return
      }
    }

    const namespace = process.env.GATSBY_ACTIVE_PROMPT || "BusinessSetup"

    setLoading(true)
    await axios
      .post(`${process.env.GATSBY_API_BASE_URL}/schedule-call`, {
        ...formData,
        namespace,
        sessionId,
        utm_source: searchParams.get("utm_source"),
        utm_campaign: searchParams.get("utm_campaign"),
        utm_term: searchParams.get("utm_term"),
        nat: searchParams.get("nat"),
        utm_content: searchParams.get("utm_content"),
        gclid: searchParams.get("gclid"),
        fbclid: searchParams.get("fbclid"),
      })
      .then(resp => {
        setLoading(false)
        setShowSuccess(true)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  const handleBlur = event => {
    const fieldName = event.target.name
    const fieldValue = event.target.value
    const prevErrors = { ...errors }

    if (!fieldValue) {
      prevErrors[fieldName] = "This field is required"
    } else {
      prevErrors[fieldName] = ""
    }

    let newErrors = {}
    for (const key in prevErrors) {
      if (prevErrors[key] !== "") {
        newErrors[key] = prevErrors[key]
      }
    }

    setErrors(newErrors)
  }

  const handleChange = event => {
    const fieldName = event.target.name
    const fieldValue = event.target.value

    setFormData(prevFormData => ({
      ...prevFormData,
      [fieldName]: fieldValue,
    }))
    setUnTouched(false)
  }

  const handleBlurPhone = () => {
    let phoneValidation = {
      target: {
        name: "phone",
        value: phoneNumber.parsedNumber,
      },
    }

    try {
      const telephone = parsePhoneNumber(
        phoneNumber.parsedNumber,
        phoneNumber.iso2 as any
      )
      if (!telephone.isValid()) {
        phoneValidation.target.value = ""
      }
    } catch (error) {
      phoneValidation.target.value = ""
    }

    handleBlur(phoneValidation)
  }

  const handleChangePhone = phone => {
    setPhoneNumber(phone)
    handleChange({
      target: {
        name: "phone",
        value: phone.parsedNumber,
      },
    })
  }

  return (
    <>
      {showSuccess && (
        <Alert
          status="success"
          borderColor="gray.50"
          borderRadius="md"
          className="form-success"
        >
          <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
            <AlertIcon my={5} boxSize="60px" />
            <AlertTitle mb={2} w="100%" textAlign="center">
              We'll be in touch soon
            </AlertTitle>
            <AlertDescription mb={5} w="100%" textAlign="center">
              Your message has been received. We'll review it and get back to
              you.
            </AlertDescription>
          </Flex>
        </Alert>
      )}

      {!showSuccess && (
        <>
          <Heading as="h6" fontSize="lg" py={0} my={0}>
            Talk to a consultant
          </Heading>
          <form onSubmit={handleSubmit} noValidate>
            <Flex justifyContent="center" flexWrap="wrap">
              <FormControl
                id="first_name"
                isRequired
                mt={4}
                isInvalid={errors["first_name"]}
                flex={{ base: "100%", md: "45%" }}
                marginRight={{ base: "0", md: "2" }}
              >
                <Input
                  name="first_name"
                  type="text"
                  size={"md"}
                  placeholder="First name"
                  bg={formData.first_name ? "white" : "gray.50"}
                  borderWidth="1px"
                  borderColor="gray.50"
                  borderRadius="md"
                  value={formData.first_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors["first_name"]}</FormErrorMessage>
              </FormControl>

              <FormControl
                id="last_name"
                isRequired
                mt={4}
                isInvalid={errors["last_name"]}
                flex={{ base: "100%", md: "45%" }}
                marginLeft={{ base: "0", md: "2" }}
              >
                <Input
                  name="last_name"
                  placeholder="Last name"
                  type="text"
                  size={"md"}
                  bg={formData.last_name ? "white" : "gray.50"}
                  borderWidth="1px"
                  borderColor="gray.50"
                  borderRadius="md"
                  value={formData.last_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors["last_name"]}</FormErrorMessage>
              </FormControl>
            </Flex>

            <FormControl
              id="email"
              isRequired
              mt={4}
              isInvalid={errors["email"]}
            >
              <Input
                name="email"
                placeholder="Email"
                type="email"
                size={"md"}
                borderWidth="1px"
                bg={formData.email ? "white" : "gray.50"}
                borderColor="gray.50"
                borderRadius="md"
                value={formData.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <FormErrorMessage>{errors["email"]}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="phone"
              isRequired
              mt={4}
              isInvalid={errors["phone"]}
            >
              <PhoneNumberInput
                size={"md"}
                country={phoneNumber.selectedCountry}
                value={formData.phone}
                options={countryOptions}
                placeholder="Phone number"
                bg={formData.phone ? "white" : "gray.50"}
                borderWidth="1px"
                borderColor="gray.50"
                borderRadius="md"
                onBlur={handleBlurPhone}
                onChange={handleChangePhone}
              />
              <FormErrorMessage>{errors["phone"]}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="nationality"
              isRequired
              mt={4}
              isInvalid={errors["nationality"]}
            >
              <Select
                iconColor="gray.200"
                name="nationality"
                borderWidth="1px"
                borderColor="gray.50"
                borderRadius="md"
                size={"md"}
                bg={formData.nationality ? "white" : "gray.50"}
                value={formData.nationality}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value="">Select your nationality</option>
                {COUNTRIES.map(country => (
                  <option value={country.name}>{country.name}</option>
                ))}
              </Select>
              <FormErrorMessage>{errors["nationality"]}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="type_business"
              isRequired
              mt={4}
              isInvalid={errors["type_business"]}
            >
              <Select
                iconColor="gray.200"
                name="type_business"
                borderWidth="1px"
                borderColor="gray.50"
                borderRadius="md"
                size={"md"}
                bg={formData.type_business ? "white" : "gray.50"}
                value={formData.type_business}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value="">Type of business</option>
                {types.map(type => (
                  <option value={type.value}>{type.label}</option>
                ))}
              </Select>
              <FormErrorMessage>{errors["type_business"]}</FormErrorMessage>
            </FormControl>

            <FormControl id="message" mt={4} isInvalid={errors["message"]}>
              <Textarea
                name="message"
                borderWidth="1px"
                borderColor="gray.50"
                bg={formData.message ? "white" : "gray.50"}
                borderRadius="md"
                placeholder="What additional information can you provide?"
                value={formData.message}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <FormErrorMessage>{errors["message"]}</FormErrorMessage>
            </FormControl>

            <Flex justifyContent="right">
              <Button
                mt={4}
                bg={
                  Object.keys(errors).length > 0 || unTouched
                    ? "gray.200"
                    : "red.full"
                }
                type="submit"
                textAlign="center"
                zIndex="0"
                disabled={Object.keys(errors).length > 0 || loading}
              >
                {loading ? "Please wait..." : "Submit"}
              </Button>
            </Flex>
          </form>
        </>
      )}
    </>
  )
}

export default ContactForm

import Section from "gatsby-theme-wild-child/src/components/Section"
import React from "react"
import BlobBackground from "./BlobBackground"
import UserInput from "./UserInput"
import {
  Grid,
  Heading,
  Image,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react"
import { useChat } from "./ChatProvider"

function HeroSection() {
  const minHeight = useBreakpointValue({
    base: "750px",
    sm: "40vh",
    md: "90vh",
  })
  const { namespace } = useChat()
  return (
    <Section
      h={["auto", "auto", "auto"]}
      minH={minHeight}
      zIndex="0"
      bg="rgba(255, 255, 255, 0.9)"
    >
      <Grid h="100%" templateColumns="1fr">
        <VStack h="100%" w="full">
          <Image
            src="https://res.cloudinary.com/wild-creative/image/upload/v1673849636/virtuzone-logo-1_fchemo.svg"
            w="full"
            maxH="40px"
            display={["block", "block", "none"]}
          />
          <Heading
            fontSize={["4xl", "5xl", "6xl", "80px", "90px", "104px"]}
            textAlign="center"
            color="dark.full"
            as="h1"
            lineHeight="0.85"
            letterSpacing="-30"
            fontWeight="bold"
          >
            {namespace?.title}
          </Heading>
          <Text
            fontWeight="400"
            fontSize={["20px", "20px", "20px", "20px", "20px", "23px"]}
            textAlign="center"
            lineHeight="1.3"
            letterSpacing="tight"
            maxW="1000px"
          >
            {namespace?.description}
          </Text>
        </VStack>
        <UserInput showTopPrompts={true} />
      </Grid>
      <BlobBackground />
    </Section>
  )
}

export default HeroSection

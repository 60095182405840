import React, { useState, useEffect } from "react";
import {
    Box,
    Flex,
    Input,
    Select,
    InputGroup,
    InputLeftElement
} from "@chakra-ui/react";
import { PhoneIcon } from '@chakra-ui/icons';


import Flag from "react-world-flags";
import { AsYouType } from "libphonenumber-js";
import { getCountryTelCode, getCountryTelIso2 } from "../../../../wc-config/countries";

export default function PhoneNumberInput({
    size,
    value,
    country,
    options,
    placeholder,
    bg,
    borderWidth,
    borderColor,
    borderRadius,
    onBlur,
    onChange,
    ...rest
}) {
    let [number, setNumber] = useState(value || "");
    let [selectedCountry, setSelectedCountry] = useState(country || "");
    let [countryCode, setCountryCode] = useState(
        getCountryTelCode(country) || ""
    );

    useEffect(() => {
        setSelectedCountry(country);
        setCountryCode(getCountryTelCode(country));
    }, [country]);

    const onCountryChange = e => {
        let value = e.target.value;
        let code = getCountryTelCode(value);
        let parsedNumber = new AsYouType().input(`${code}${number}`);

        setCountryCode(code);
        setSelectedCountry(value);
        onChange(parsedNumber);
    };

    const onHandleBlur = (e) => {
        onBlur(e);
    }

    const onPhoneNumberChange = e => {
        let value = e.target.value;
        let parsedNumber = new AsYouType().input(`${countryCode}${value}`);
        const iso2 = getCountryTelIso2();
        const phoneNumber = {
            selectedCountry,
            iso2,
            countryCode,
            parsedNumber,
            number: value
        };

        setNumber(value);
        onChange(phoneNumber);
    };

    return (
        <InputGroup size={size} {...rest}>
            <InputLeftElement width="4rem">
                <Select
                    iconColor="gray.200"
                    top="1"
                    left="0"
                    zIndex={1}
                    bottom={0}
                    opacity={0}
                    height="100%"
                    position="absolute"
                    value={selectedCountry}
                    onChange={onCountryChange}
                >
                    <option value="" />
                    {options.map(option => (
                        <option value={option.value}>{option.label}</option>
                    ))}
                </Select>

                <Flex pl={2} width="100%" alignItems="center">
                    {selectedCountry ? (
                        <Box mr="4px" flex={1}>
                            <Flag width="50%" code={selectedCountry || ""} />
                        </Box>
                    ) : (
                            <PhoneIcon boxSize={4} color="gray.200" />
                        )}
                </Flex>
            </InputLeftElement>
            <Input
                pl="4rem"
                type="tel"
                value={number}
                pattern="[0-9]"
                bg={bg}
                placeholder={placeholder}
                borderWidth={borderWidth}
                borderColor={borderColor}
                borderRadius={borderRadius}
                onBlur={onHandleBlur}
                onChange={onPhoneNumberChange}
            />
        </InputGroup>
    );
}

PhoneNumberInput.defaultProps = {
    options: [],
    size: "md"
};

import {
  IconButton,
  Icon,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react"
import React from "react"
import { BsFiles } from "react-icons/bs"
import { useChat } from "./ChatProvider"

function Clear({ ...props }) {
  const { references } = useChat()
  const [showReferences, setShowReferences] = React.useState(false)

  function toggleReferences() {
    setShowReferences(!showReferences)
  }

  return references.length ? (
    <Box {...props}>
      <Box position="relative">
        {showReferences && (
          <HStack
            position="absolute"
            bottom="60px"
            right="10px"
            zIndex="10"
            minW="400px"
            bg="white"
            id="references"
            rounded="md"
            boxShadow="md"
            p={2}
          >
            <Accordion allowToggle defaultIndex={0} w="full" rounded="md">
              {references.map((reference, index) => (
                <AccordionItem>
                  <AccordionButton
                    bg="red.full"
                    color="white"
                    _hover={{
                      bg: "red.600",
                    }}
                  >
                    <Box as="span" flex="1" textAlign="left" fontSize="16px">
                      Reference {index + 1}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    <Box
                      mb={4}
                      bg="gray.100"
                      fontSize="14px"
                      fontWeight="700"
                      letterSpacing="wide"
                    >
                      {reference.metadata.url}
                    </Box>
                    <Box fontSize="15px">
                      {reference.metadata.text
                        .replace(reference.metadata.title, "")
                        .replace(reference.metadata.heading, "")}
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </HStack>
        )}
        <IconButton
          onClick={toggleReferences}
          boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 50px"
          size="lg"
          bg="red.full"
          rounded="lg"
          aria-label="back"
          position="relative"
          icon={<Icon boxSize="20px" color="white" as={BsFiles} />}
        />
      </Box>
    </Box>
  ) : null
}

export default Clear

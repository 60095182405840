import Section from "gatsby-theme-wild-child/src/components/Section"
import React, { useEffect, useRef } from "react"

import TypingAnimation from "./TypingAnimation"
import { useChat } from "./ChatProvider"
import { useTheme, VStack, useBreakpointValue, Image } from "@chakra-ui/react"
import Response from "./Response"
import Clear from "./Clear"
import UserInput from "./UserInput"

function Transcript({ onOpen }) {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { transcriptHistory, isListening, showContactForm } = useChat()
  const { variables } = useTheme()
  const listRef = useRef(null)

  useEffect(() => {
    scrollToBottim()
  }, [transcriptHistory, showContactForm])

  const scrollToBottim = () => {
    if (listRef.current !== null) {
      listRef.current.scrollTo({
        top: listRef.current.scrollHeight + 500,
        behavior: "smooth",
      })
    }
  }

  return (
    <Section bg="gray.full" minH="100vh" p={isMobile ? "0" : "5"}>
      <Clear
        position="fixed"
        top={["15px", "90px", "120px"]}
        left={["15px", "36px", "120px"]}
        zIndex="99999"
      />
      <Image
        src="https://res.cloudinary.com/wild-creative/image/upload/v1673849636/virtuzone-logo-1_fchemo.svg"
        maxH="40px"
        display={["block", "block", "none"]}
        position="fixed"
        top={["20px", "90px", "120px"]}
        left={["80px", "36px", "120px"]}
        zIndex="99999"
      />

      <VStack
        w="full"
        h="full"
        justify="space-between"
        minH="80vh"
        maxH="80vh"
        overflowY="auto"
        pt={[12, 12, 0]}
        ref={listRef}
      >
        <VStack
          w="full"
          maxW={variables.maxContentWidth}
          spacing={[6, 6, 12]}
          py={[8, 8, 20]}
          px={[4, 4, 20]}
        >
          {transcriptHistory.map((transcript, index) => (
            <Response transcript={transcript} key={index} onOpen={onOpen} />
          ))}

          {isListening && <TypingAnimation />}
        </VStack>

        <UserInput showTopPrompts={false} />
      </VStack>
    </Section>
  )
}

export default Transcript

import { IconButton, Icon } from "@chakra-ui/react"
import React from "react"
import { MdKeyboardBackspace } from "react-icons/md"
import { useChat } from "./ChatProvider"

function Clear({ ...props }) {
  const { clearChat } = useChat()

  return (
    <IconButton
      onClick={clearChat}
      boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 50px"
      size="lg"
      bg="white"
      rounded="full"
      aria-label="back"
      {...props}
      icon={<Icon boxSize="20px" color="red.full" as={MdKeyboardBackspace} />}
    />
  )
}

export default Clear
